import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

import Content from 'organisms/Content'
import SEO from 'molecules/Seo'
import MenuSecondary from 'molecules/MenuSecondary'
import PageTitle from 'atoms/PageTitle'
import Main from 'organisms/Main'
import PostAuthor from 'atoms/PostAuthor'
import Blocks from 'molecules/Blocks'
import Sidebar from 'organisms/Sidebar'
import StickyNavigation from 'molecules/StickyNavigation'

/**
 * Template Post Single
 * @author                            Content Pilot
 * @param  {object}  props             The component attributes as props.
 * @param  {object}  props.data        The page data.
 * @param  {object}  props.pageContext The page context.
 * @return {Element}                   The Post Single template.
 */
export default function SinglePost({data, pageContext}) {
  const {blocks} = pageContext
  const {title, seo} = data.wpPost
  const {metaTitle, metaDesc, opengraphType, opengraphUrl, opengraphImage} = seo

  return (
    <Content className="blog">
      <SEO
        title={metaTitle}
        metaDesc={metaDesc}
        opengraphType={opengraphType}
        opengraphUrl={opengraphUrl}
        opengraphImage={opengraphImage}
      />
      <MenuSecondary />
      <PageTitle title={title} fullWidth />
      <Content internal>
        <Main>
          <PostAuthor />
          <Blocks blocks={blocks} />
        </Main>
        <Sidebar>
          <StickyNavigation blocks={blocks} />
        </Sidebar>
      </Content>
    </Content>
  )
}

export const query = graphql`
  query PostByID($id: String!) {
    wpPost(id: {eq: $id}) {
      id
      title
      seo {
        metaTitle: title
        metaDesc
        opengraphType
        opengraphUrl
        opengraphImage {
          mediaItemUrl
        }
      }
    }
  }
`

SinglePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
  pageContext: PropTypes.object
}
