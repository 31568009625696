import React from 'react'

import Avatar from 'atoms/Avatar'

import {wrapper, name} from './PostAuthor.module.scss'

/**
 * Render the PostAuthor component.
 * @author  Content Pilot
 * @return {Element} The PostAuthor component.
 */
export default function PostAuthor() {
  return (
    <div className={wrapper}>
      <Avatar />
      <p className={name}>
        <strong>Deborah McMurray</strong>
      </p>
    </div>
  )
}
